// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
 
const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'JieLong',
      component: () => import('../views/H5/Jielong.vue')
    },
    {
      path: '/saveFrom',
      name: 'JieLongFrom',
      component: () => import('../views/H5/JielongForm.vue')
    },
  // 添加更多的路由...
];
 
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
 
export default router;