<template>
  <router-view/>
</template>

<script lang="ts">
export default {}
</script>

<style>

</style>
