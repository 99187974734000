import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import elementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/assets/css/common.css'

import Vant from "vant"
import "vant/lib/index.css"


createApp(App).use(router).use(Vant).use(elementPlus, {
    locale: zhCn,
}).mount('#app')
